import React from "react"
// import { graphql } from "gatsby"
// import {Container, Row, Col} from "react-bootstrap"
import Seo from "../components/seo"

import Layout from "../components/layout";
import YandexMap from "../components/Modules/YandexMap"
import ContactForm from "../components/Modules/ContactForm/contactform"



export default function Project() {


    return(
        <>
            <Seo 
                title="Заказать банные чаны в Екатеринбурге по доступной цене"
                description="Наши контакты офиса и производства банных чанов в Екатеринбурге и области. Доставка по всей России. Звоните, пишите, заказывайте!"
            />
            <Layout>
                <div className="container">
                    <div className="contacts-info-block">
                        <p className="section-title section-title--green">КОНТАКТЫ</p>
                    </div>
                    <p><strong>Заказать чан в Екатеринбурге можно по адресу:</strong> Свердловская область, Екатеринбург, Сибирский тракт, 12 корпус 7</p>
                    <p><strong>Производственный цех находится по адресу:</strong> Свердловская область, город Первоуральск, Динасовское шоссе, 29</p>
                    <p><strong>Адрес офиса в Первоуральске находится по адресу:</strong> Свердловская область, город Первоуральск, ул. Вайнера 10А оф.312</p>
                </div>
                <YandexMap />
                <div style={{backgroundColor: '#77a237'}}>
                    <ContactForm />
                </div>
                
            </Layout>
        
        </>
    )
}