import React from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps';


const mapState = { center: [56.863231, 60.320950], zoom: 11, height: "500px", behaviors: ['drag', "disable('scrollZoom')"] };
// const coordinates = [
//   [55.74689689342914, 37.60188872176091],
//   [55.76754150393748, 49.20624545413351]
// ];

// , position: 'absolute', bottom: '0'          
// ref.behaviors.disable('scrollZoom');
// GeolocationControl, FullscreenControl


// style={{width: '100%', height: '1100px'}}

const MapBlock = () => (
  <>
  <YMaps>
    <Map state={mapState} className="yandex-map" modules={["package.full"]} 
      instanceRef={ref => {
            if (ref) {
              ref.events.add("click", e => {
                ref.balloon.close();
              });
            }
            // ref.behaviors.enable('drag');
          }}
        
    >

{/* <FullscreenControl /> */}
{/* <GeolocationControl /> */}

          <Placemark
            modules={["geoObject.addon.balloon"]}
            geometry={[56.909476, 59.907657]}
            properties={{
                iconCaption: 'Производство "Чан №1"',
                balloonContent: "Мы работаем: <p><strong>Пн-Пт:</strong>с 9:00 до 20:00</p><p><strong>Сб-Вс:</strong>выходные дни</p>"
            }}
          />

          <Placemark
            modules={["geoObject.addon.balloon"]}
            geometry={[56.907357, 59.965330]}
            properties={{
                iconCaption: 'Офис "Чан №1"',
                balloonContent: "Офис в Первоуральске работает: <p><strong>Пн-Пт:</strong>с 9:00 до 20:00</p><p><strong>Сб-Вс:</strong>выходные дни</p>"
            }}
          />

          <Placemark
            modules={["geoObject.addon.balloon"]}
            geometry={[56.819306, 60.651580]}
            properties={{
                iconCaption: 'Офис "Чан №1"',
                balloonContent: "Мы работаем: <p><strong>Пн-Пт:</strong>с 9:00 до 20:00</p><p><strong>Сб-Вс:</strong>выходные дни</p>"
            }}
          />



    {/* {coordinates.map(coordinate => <Placemark geometry={coordinate} properties={{iconContent: 'Офис "Рефинжиниринг'}} />)} */}

    </Map>
  </YMaps>
  </>
);

export default MapBlock