import React from 'react'
import MapBlock from './mapblock'

const YndexMap = () => {
    return (
        <>
            <MapBlock />
        </>
    )
}

export default YndexMap